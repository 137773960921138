@import "../../common/utils/scss/main.scss";
.FormGroupServices {
  //margin: 0 10px 20px 0px;
  padding: 0;
  border-style: none;
  will-change: opacity, transform;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.2);
}

.FormRowServicesv {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  padding-left: 10px;
}

.FormRowServices:first-child {
  border-top: none;
}

.FormRowLabelServices {
  width: 15%;
  min-width: 80px;
  padding: 11px 0;
  color: $light-grey;
  font-size: $base-font - 0.4;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

.FormRowInputServices:-webkit-autofill,
.FormRowInputServices:-webkit-autofill:hover,
.FormRowInputServices:-webkit-autofill:focus,
.FormRowInputServices:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  border: solid 1px rgb(214, 214, 214);
  border-radius: 4px;
}

.FormRowInputServices {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 11px 15px 11px 11px;
  color: #000;
  background-color: #fff;
  border: solid 1px rgb(214, 214, 214);
  border-radius: 4px;
  animation: 1ms void-animation-out;
}

.FormRowHelpTextServices {
  font-size: 12px;
  padding: 4px 16px 0 16px;
  margin-top: 4px;
  color: grey;
}

.FormRowInputServices:focus {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 11px 15px 11px 11px;
  color: #000;
  font-weight: 500;
  background-color: #fff;
  border: 0px;
  animation: 1ms void-animation-out;
  border: solid 1px rgb(51, 51, 51);
  border-radius: 4px;
}

.FormRowInputServices::placeholder {
  color: #a7a7a7;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.SubmitButton {
  width: 100%;
  @include border-radius(4px);
  background-color: #ffd865;
  color: #333;
  font-size: $base-font;
  font-weight: bold;
  padding: 15px 38px;
  margin-top: 30px;
}

.SubmitButton:active {
  background-color: $hold-btn-bg;
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: $hold-btn-disabled-bg;
  box-shadow: none;
}

.ErrorMessage {
  color: #000;
  display: flex;
  justify-content: center;
  padding: 35px 0px 0px 0px;
  font-size: 13px;
  margin-top: 40px;
  width: 100%;
  transform: translateY(-15px);
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}

.InputElement {
  padding: 11px 15px 11px 10px;
}

.extra-services-subtitle {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 8px;
  padding-right: 0 !important;
  color: $light-grey;
  font-size: $base-font - 0.5;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.5;
  display: inline-block;
  font-family: $font-stack;
}

.checkin-screen {
  margin-top: -78px;
  h2 {
    color: black;
  }
}
